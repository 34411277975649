var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',[_c('v-container',{staticClass:"fill-height"},[_c('h1',[_vm._v("Order")]),_c('v-spacer'),(_vm.form.delivered ==='delivered')?_c('h1',{staticClass:"design"},[_vm._v(_vm._s(_vm.form.delivered))]):_vm._e(),(_vm.form.delivered ==='canceled')?_c('h1',{staticClass:"cancel"},[_vm._v(_vm._s(_vm.form.delivered))]):_vm._e(),(_vm.form.delivered ==='pending')?_c('h1',{staticClass:"pending"},[_vm._v(_vm._s(_vm.form.delivered))]):_vm._e(),(_vm.form)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_vm._v("Name: "),_c('span',{staticClass:"design"},[_vm._v(_vm._s(_vm.form.name))])]),_c('v-card-subtitle',[_vm._v("Email: "),_c('span',{staticClass:"design"},[_vm._v(_vm._s(_vm.form.email))])]),_c('v-card-title',[_vm._v("Code: "),_c('span',{staticClass:"design"},[_vm._v(_vm._s(_vm.form.code))])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_vm._v("Phone: "),_c('span',{staticClass:"design"},[_vm._v(_vm._s(_vm.form.phone))])]),_c('v-card-title',[_vm._v("House: "),_c('span',{staticClass:"design"},[_vm._v(_vm._s(_vm.form.house))])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"min-height":"130"}},[_c('v-card-title',[_vm._v(" Location:"),_c('span',{staticClass:"design"},[_vm._v(_vm._s(_vm.form.location))])]),_c('v-card-title',[_vm._v(" Street:"),_c('span',{staticClass:"design"},[_vm._v(_vm._s(_vm.form.street))])])],1)],1)],1):_vm._e(),(_vm.cart.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',[_c('div',[_c('h1',[_vm._v("Products")])]),_c('v-data-table',{staticClass:"elevation-1 display-2",staticStyle:{"font-size":"10rem"},attrs:{"headers":_vm.cartheader,"items":_vm.cart,"item-key":"ID","footer-props":{
                'items-per-page-options': [5,10, 20, 30, 40, 50]
              },"items-per-page":10},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
              var item = ref.item;
return [_vm._v(" ksh"+_vm._s(_vm.formatcurrency(item.price))+" ")]}},{key:"item.total",fn:function(ref){
              var item = ref.item;
return [_vm._v(" ksh"+_vm._s(_vm.formatcurrency(item.quantity * item.price))+" ")]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green"},on:{"click":function($event){return _vm.View(item.code)}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.tdf)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',[_c('div',[_c('h1',[_vm._v("Addons")])]),_c('v-data-table',{staticClass:"elevation-1 display-2",staticStyle:{"font-size":"10rem"},attrs:{"headers":_vm.addonsheader,"items":_vm.stuffs,"item-key":"ID","footer-props":{
                'items-per-page-options': [5,10, 20, 30, 40, 50]
              },"items-per-page":10},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
              var item = ref.item;
return [_c('v-img',{staticClass:"grey darken-4",attrs:{"src":("" + _vm.host + (item.image)),"contain":"","max-height":"50","max-width":"50"}})]}}],null,true)})],1)],1),(_vm.picturer)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-img',{staticClass:"grey darken-4",attrs:{"src":("" + _vm.host + (_vm.picturer[0].name)),"contain":"","max-height":"200"}})],1):_vm._e()],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('p',[_vm._v("Instruction: "),_c('br'),_vm._v(_vm._s(_vm.form.instructions))])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_vm._v("Amount: ksh"+_vm._s(_vm.formatcurrency(_vm.form.amount)))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }