<template>
  <v-container>
    
<DataViewer :source="source" :headers="headers" :create="create" :title="title" :show="show" />
  </v-container>

</template>
<script>
import DataViewer from '@/components/viewers/dataviewerorder.vue'
export default {
      name: 'seos',
    components:{
        DataViewer,
    },
      data(){
          return{
              source: '/api/sales', 
              show: '/sales',
              title:'sales',
              create: '/sales/create',
              headers:[
                { text: 'Name', value: 'name' },
                { text: 'Phone', value: 'phone' },
                { text: 'Email', value: 'email' },
                { text: 'Order Id', value: 'order' },
                { text: 'Amount', value: 'amount' },
                { text: 'Status', value: 'status' },
                { text: 'Delivered', value: 'delivered' },
                { text: 'Actions', value: 'actions', sortable: false }
              ]
          }
      },
}
</script>

<style>

</style>