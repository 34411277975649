<template>
<v-container>
  <v-row>
<v-col>
    <v-toolbar
    >
<v-toolbar-title>{{title}}</v-toolbar-title>
<v-spacer></v-spacer>

<v-btn :to="create">
        create
      </v-btn>
    </v-toolbar>
    <template>
  <v-expansion-panels>
    <v-expansion-panel
    >
      <v-expansion-panel-header>
        filter
      </v-expansion-panel-header>
      <v-expansion-panel-content>
       <v-row>
        <v-col
          cols="12"
          md="9"
        >
          <v-text-field
            v-model="search"
            label="Search query"
            required
            @keyup="GetData"
          ></v-text-field>
        </v-col>
       
    <v-col
      cols="12"
      sm="6"
      md="2"
      v-if="custom"
    >
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="searchq3"
            label="And Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="searchq3"
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
    </v-col>

        <v-col
          cols="12"
          md="1"
        >
         <v-btn class="ma-2" 
      depressed
      color="success"
      @click="GetData"
    >
      Search
    </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
         <v-btn class="ma-2" 
      depressed
      color="primary"
      @click="resetFilter"
    >
      Reset
    </v-btn>
        </v-col>
      </v-row></v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
    </template>

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page=pagesize
            item-key="ID"
            class="elevation-1 display-2"
            style="font-size:10rem"
            hide-default-footer
          >
          <template v-slot:[`item.featured`]="{ item }">
                 <v-switch
                 color="orange"
            v-model="item.featured"
            @change="changing(item)"
          ></v-switch>
            </template>
          <template v-slot:[`item.hotdeals`]="{ item }">
           <div> 
          <v-switch
            v-model="item.hotdeals"
            @change="changing1(item)"
          ></v-switch></div>
            </template>
             <template v-slot:[`item.url`]="{ item }">
              {{item.url}}<v-icon
                small
                color="red"
                class="mr-2"
                @click="createurl(item)"
              >
                mdi-pencil
              </v-icon>
             </template>
          <template v-slot:[`item.admin`]="{ item }">
           <div> <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>{{ item.admin}}</div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                color="green"
                class="mr-2"
                @click="View(item.code)"
              >
                mdi-eye
              </v-icon>
              <v-icon
                small
                color="orange"
                class="mr-2"
                @click="Edit(item.code)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                class="mr-2"
                color="red"
                @click="Delete(item)"
              >
                mdi-delete
              </v-icon>
          
            </template>
          </v-data-table>
<div class="cent">
    <v-btn 
      depressed
      color="primary"
      @click="prevous"
    >
      Previous
    </v-btn> 

    <input type="number" class="texta" v-model="page" @change="getresults(page)" @keyup="getresults(page)" @keydown="getresults(page)"
    />
    <v-btn 
      depressed
      color="primary"
      @click="next"
    >
      Next
    </v-btn>  of {{total}} results</div>

</v-col>
  </v-row>
  <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Update the Receipt Status</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                 <v-select
                    v-model="status"
                  :items="itemos"
                  label="Status"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="save()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <url :dialogurl="dialogurl" :closeurl="closeurl" :product="prod" />
  </v-container>
</template>
<script>
 import axios from '@/axios'
import url from '../edits/url.vue'
  export default {
  components: { url },

    
    props: ['source', 'headers', 'create', 'title','show'],
    data(){
        return{
            dialogurl:false,
            items:[],
            dialog: false,
            showFilter: false,
            form: {},
            editedIndex: 0,
            prod:{},
            search:'',
            custom: false,
            // date: new Date().toISOString().substr(0, 10),
            menu: false,
            modal: false,
            menu2: false,
            page:1,
            pagesize: 50,
            total:0,
            status: '',
               
            itemos:[ 
              "admin",
              "notadmin",
            ]   
        }
    },
    created(){
          this.GetData()
       },
    methods:{
    createurl(item){
      console.log("-----------------------")
      this.prod = item
      this.dialogurl = true
    },    
    closeurl(){
      this.dialogurl = false
      this.GetData()
    },
      prevous(){
        if (this.page > 1){
          this.page --
          this.GetData()
        }else{
          return
        }
      },
      next(){
        var tr = this.items.length - this.pagesize
        if (tr == 0){
          this.page ++
          this.GetData()
        }else{
          return
        }
      },
        editItem(item) {
          //  console.log(item)
          this.editedIndex = this.items.indexOf(item);
          this.form = Object.assign({}, item);
          this.dialog = true;
        },
        close() { 
          this.dialog = false;
          this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });
        },
        async save() {
          try {
              this.$store.commit("setLoaderTrue")
            let fd = new FormData();
            fd.append("code", this.form.code);
            fd.append("admin", this.status);
            await axios.put(`api/users/admin/${this.form.code}`, fd);
            this.close()
            this.GetData()
                this.$store.commit("setLoader")
          } catch (err) {
            this.close()
            this.snackbar = true;
            this.errs = err.response.data
                this.$store.commit("setLoader")
          }
        },
        
        async changing(item) {
          try {
              this.$store.commit("setLoaderTrue")
            let fd = new FormData();
            console.log("hellooooooooooo",item.featured)
            fd.append("status", item.featured);
            await axios.put(`api/featured/${item.code}`, fd);
            this.close()
            this.GetData()
                this.$store.commit("setLoader")
          } catch (err) {
            this.close()
            this.snackbar = true;
            this.errs = err.response.data
                this.$store.commit("setLoader")
          }
        },
        
        async changing1(item) {
          try {
            console.log("hellooooooooooo",item)
              this.$store.commit("setLoaderTrue")
            let fd = new FormData();
            fd.append("status", item.hotdeals);
            await axios.put(`api/hotdeals/${item.code}`, fd);
            this.close()
            this.GetData()
                this.$store.commit("setLoader")
          } catch (err) {
            this.close()
            this.snackbar = true;
            this.errs = err.response.data
                this.$store.commit("setLoader")
          }
        },

        async Delete(item) {
          try {
            this.$store.commit("setLoaderTrue")
            alert(`are you sure you want to delete ${item.name}`)
            await axios.delete(`/api${this.show}/${item._id}`);
            this.close()
            this.GetData()
                this.$store.commit("setLoader")
          } catch (err) {
            this.close()
            this.snackbar = true;
            this.errs = err.response.data
                this.$store.commit("setLoader")
          }
        },
    View(code){

      this.$router.push(`${this.show}/show/${code }`)
    } ,

    Edit(code){
      this.$router.push(`${this.show}/${code}/edit`)
    } ,
    getresults(page){
      this.page = page
      this.GetData()
    },
        selected(val){
          if (val === 'custom'){
            this.custom = true
          }else {
            this.custom = false
          }
        },
        resetFilter(){
            this.search = ''
            this.custom = false
            this.page=1,
            this.pagesize=50
            this.GetData()
        },
       async GetData(){
        try{
          this.$store.commit("setLoaderTrue")
          let p = this
            // console.log(token)
           const {data} = await axios.get(`${this.source}?search=${p.search}&&page=${p.page}&&pagesize=${p.pagesize}`)
            this.items = data.data
            this.total = data.total

            // console.log(data)
          this.$store.commit("setLoader")
        }catch(err){
           console.log(err)
          this.$store.commit("setLoader")
        }
    },  
    }
  }
</script>
<style>

.cent{
  display: flex;
  justify-content: center;
  align-items:center ;
  padding: auto;
}
.texta{
  outline: gray;
}
</style>