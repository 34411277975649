var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"fill-height"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-row',{staticClass:"fill-height"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card-text',{staticClass:"mt-12"},[_c('h1',{staticClass:"text-center display-2 teal--text text--accent-3"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),[_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"value":true,"right":"","shaped":"","bottom":"","dismisable":"","color":"red"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('h2',[_vm._v(_vm._s(_vm.errs.error))]),_c('p',[_vm._v(_vm._s(_vm.errs.message))])])],_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',[_c('p',[_vm._v("Name "+_vm._s(_vm.form.name))])])],1),_c('v-row',[(_vm.data)?_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-autocomplete',{attrs:{"items":_vm.data,"chips":"","clearable":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"title","label":"Search subject...","solo":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("Subject")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-box-outline ")]),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.title))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-check-box-outline")])],1)]}}],null,false,2482202886),model:{value:(_vm.form.subject),callback:function ($$v) {_vm.$set(_vm.form, "subject", $$v)},expression:"form.subject"}})],1):_vm._e(),(_vm.stars)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.stars,"chips":"","clearable":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"title","label":"Rating ...","solo":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Rating "),_c('strong',[_vm._v("Stars")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-box-outline ")]),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.title))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-check-box-outline")])],1)]}}],null,false,556642287),model:{value:(_vm.form.rating),callback:function ($$v) {_vm.$set(_vm.form, "rating", $$v)},expression:"form.rating"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h2',[_vm._v("Review")]),_c('vue-editor',{model:{value:(_vm.form.review),callback:function ($$v) {_vm.$set(_vm.form, "review", $$v)},expression:"form.review"}})],1)],1)],1)],1)],2),_c('div',{staticClass:"text-center mt-3"},[_c('v-btn',{staticClass:"ma-3",attrs:{"dark":""},on:{"click":function($event){return _vm.Back()}}},[_vm._v("Back")]),_c('v-btn',{staticClass:"ma-3",attrs:{"color":"teal accent-3"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }