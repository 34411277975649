var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"fill-height"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"elevation-12"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-row',{staticClass:"fill-height"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card-text',[_c('h1',{staticClass:"teal--text text--accent-3"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),[_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"value":true,"right":"","shaped":"","bottom":"","dismisable":"","color":"red"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('h2',[_vm._v(_vm._s(_vm.errs.error))]),_c('p',[_vm._v(_vm._s(_vm.errs.message))])])],_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Name","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Title","error-messages":errors,"required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"meta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Meta","required":""},model:{value:(_vm.form.meta),callback:function ($$v) {_vm.$set(_vm.form, "meta", $$v)},expression:"form.meta"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"altertag","rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Altertag","error-messages":errors,"required":""},model:{value:(_vm.form.altertag),callback:function ($$v) {_vm.$set(_vm.form, "altertag", $$v)},expression:"form.altertag"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_vm._v(_vm._s(_vm.form.supercategory)+" "),_c('v-autocomplete',{attrs:{"items":_vm.supercategory,"chips":"","clearable":"","item-text":"name","item-value":"_id","label":"Search majorcat...","solo":""},on:{"change":function($event){return _vm.GetMajorcategory(_vm.supercati)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("supercategory")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-box-outline ")]),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.title))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-check-box-outline")])],1)]}}],null,true),model:{value:(_vm.supercati),callback:function ($$v) {_vm.supercati=$$v},expression:"supercati"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_vm._v(" "+_vm._s(_vm.form.category)+" "),_c('v-autocomplete',{attrs:{"items":_vm.categorylist,"chips":"","clearable":"","item-text":"name","item-value":"_id","label":"Search category...","solo":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("category")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-box-outline ")]),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.title))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-check-box-outline")])],1)]}}],null,true),model:{value:(_vm.categoryi),callback:function ($$v) {_vm.categoryi=$$v},expression:"categoryi"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"primary",on:{"click":_vm.createitem}},[_vm._v("Add Specifications")]),(_vm.form.services)?_c('div',_vm._l((_vm.form.services),function(item,i){return _c('ul',{key:i},[_c('li',[_vm._v(_vm._s(item.name)+" at "+_vm._s(item.price)),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"red"},on:{"click":function($event){return _vm.remove(i)}}},[_vm._v("X")])],1)])}),0):_c('div',[_vm._v("Add Specs")])],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newarrivals),expression:"form.newarrivals"}],attrs:{"type":"checkbox","name":"newarrivals"},domProps:{"checked":Array.isArray(_vm.form.newarrivals)?_vm._i(_vm.form.newarrivals,null)>-1:(_vm.form.newarrivals)},on:{"change":function($event){var $$a=_vm.form.newarrivals,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "newarrivals", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "newarrivals", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "newarrivals", $$c)}}}}),_c('label',{attrs:{"for":"newarrivals"}},[_vm._v(" Newarrivals")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"source","rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Source","error-messages":errors,"required":""},model:{value:(_vm.form.source),callback:function ($$v) {_vm.$set(_vm.form, "source", $$v)},expression:"form.source"}})]}}],null,true)})],1),(_vm.suppliers)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_vm._v(" "+_vm._s(_vm.form.supplier)+" "),_c('v-autocomplete',{attrs:{"items":_vm.suppliers,"chips":"","clearable":"","item-text":"name","item-value":"name","label":"Search Supplier...","solo":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("Supplier")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-box-outline ")]),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.title))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-check-box-outline")])],1)]}}],null,true),model:{value:(_vm.form.supplier),callback:function ($$v) {_vm.$set(_vm.form, "supplier", $$v)},expression:"form.supplier"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h2',[_vm._v("Product footer")]),_c('vue-editor',{model:{value:(_vm.form.footer),callback:function ($$v) {_vm.$set(_vm.form, "footer", $$v)},expression:"form.footer"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-file-input',{ref:"file",attrs:{"accept":"image/*","label":"Change Image","required":"","type":"file","multiple":"multiple"},on:{"change":_vm.onFile}})],1)],1),(_vm.form.images)?_c('v-row',_vm._l((_vm.form.images),function(img,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"3"}},[_c('v-card',[_c('v-img',{staticClass:"grey darken-4",attrs:{"src":(_vm.host + "/" + (img.name)),"contain":"","max-height":"100"}})],1)],1)}),1):_vm._e()],1)],1)],2),_c('div',{staticClass:"text-center mt-3"},[_c('v-btn',{staticClass:"ma-3",attrs:{"dark":""},on:{"click":function($event){return _vm.Back()}}},[_vm._v("Back")]),_c('v-btn',{staticClass:"ma-3",attrs:{"color":"teal accent-3"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")])],1)],1)],1)]}}])})],1)],1)],1)],1),_c('itemmodal',{attrs:{"dialogitemi":_vm.dialogitemi,"itemiclose":_vm.itemiclose,"savor":_vm.savor}}),_c('categorymodal',{attrs:{"dialogcategory":_vm.dialogcategory,"categoryclose":_vm.categoryclose,"majorcode":_vm.majorc}}),_c('majorcatmodal',{attrs:{"dialogmajorcat":_vm.dialogmajorcat,"majorcatclose":_vm.majorcatclose}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }