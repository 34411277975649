<template>
  <v-container> 
    <v-snackbar
      :timeout="timeout"
      v-model="snackbar"
      :value="true"
      right
      shaped
      bottom
      dismisable
      color="red"
    >
  <h2>{{errs.error}}</h2>
  <p>{{errs.message}}</p>
    </v-snackbar>
 <v-card class="ma-3 pa-2">
    <v-row>
      <v-col
          cols="12"
          md="3"
        ><v-card-title> Upload A file</v-card-title></v-col>
      <v-col
          cols="12"
          md="6"
        >   <v-file-input  
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            label="Upload a file"
            required
            type="file"
            @change="onFile"
          ></v-file-input>
          </v-col>
      <v-col
          cols="12"
          md="3"
        > 
        <v-btn class="ma-2" 
      depressed
      color="primary"
      @click="UploadFile()"
    >
      Upload
    </v-btn></v-col>
    </v-row>
    <v-row>
      <v-col
          cols="12"
          md="3"
        ><v-card-title> Search and Replace</v-card-title></v-col>
      <v-col
          cols="12"
          md="3"
        >  
        <v-text-field
            v-model="form.old"
            label="Old"  
          ></v-text-field>
          </v-col>
           <v-col
          cols="12"
          md="3"
        >  <v-text-field
            v-model="form.new"
            label="New"
          ></v-text-field>
          </v-col>
      <v-col
          cols="12"
          md="3"
        > 
        <v-btn class="ma-2" 
      depressed
      color="primary"
      @click="Replace"
    >
      Replace
    </v-btn></v-col>
    </v-row>
 </v-card>
<DataViewer :source="source" :headers="headers" :create="create" :title="title" :show="show" />
  </v-container>

</template>
<script>
 import axios from '@/axios'
import DataViewer from '@/components/viewers/dataviewerpagination.vue'
export default {
      name: 'orders',
    components:{
        DataViewer,
    },
      data(){
          return{
              form: {
                old: "",
                new: ""
              },
              file: "",
              errs : "",
              snackbar: false,
              title:'orders',
              source: '/api/orders',
              create: '/orders/create',
              show:'/orders',
              headers:[
                // { text: 'Name', value: 'name' },
                { text: 'Subject', value: 'subject' },
                { text: 'Review', value: 'review' },
                { text: 'Order', value: 'code' },
                { text: 'Rating', value: 'rate' },
                { text: 'Actions', value: 'actions', sortable: false }
              ]
          }
      },
      methods:{
      onFile(e){
        console.log(e)
        this.file = e
      },
        async UploadFile() {
          try {
            this.$store.commit("setLoaderTrue")
            let fd = new FormData();
            fd.append("file", this.file);
            await axios.post(`api/orders/uploadfile`, fd);
            this.$router.push("/dashboard")
            this.$store.commit("setLoader")
          } catch (err) {
            this.snackbar = true;
            this.errs = err.response.data
            this.$store.commit("setLoader")
          }
        },
        async Replace() {
          try {
            this.$store.commit("setLoaderTrue")
            await axios.post(`api/replace`, {old: this.form.old, new: this.form.new});
            this.$router.push("/dashboard")
            this.$store.commit("setLoader")
          } catch (err) {
            this.snackbar = true;
            this.errs = err.response.data
            this.$store.commit("setLoader")
          }
        },
        
      }
}
</script>

<style>

</style>