var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialogproducts),callback:function ($$v) {_vm.dialogproducts=$$v},expression:"dialogproducts"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[(_vm.items)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.items,"chips":"","clearable":"","hide-details":"","hide-selected":"","item-text":"name","item-value":"title","label":"Search Product...","solo":""},on:{"change":function($event){return _vm.getproduct(_vm.product)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("Supplier")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-box-outline ")]),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.title))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-check-box-outline")])],1)]}}],null,false,3619677486),model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":"Description","required":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }