var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"auto",attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Change Password")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""}}),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-home")])],1)]}}],null,true)},[_c('span',[_vm._v("Home")])])],1),[_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"value":true,"right":"","shaped":"","bottom":"","dismisable":"","color":"red"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('h2',[_vm._v(_vm._s(_vm.errs.error))]),_c('p',[_vm._v(_vm._s(_vm.errs.message))])])],_c('v-card-text',[_c('v-form',[_c('validation-provider',{attrs:{"name":"oldpassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"key","error-messages":errors,"label":"Old password","type":"password","required":""},model:{value:(_vm.oldpassword),callback:function ($$v) {_vm.oldpassword=$$v},expression:"oldpassword"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"newpassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"key","error-messages":errors,"label":"New password","type":"password","required":""},model:{value:(_vm.newpassword),callback:function ($$v) {_vm.newpassword=$$v},expression:"newpassword"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"confirmpassword","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"key","error-messages":errors,"label":"Confirm password","type":"password","required":""},model:{value:(_vm.confirmpassword),callback:function ($$v) {_vm.confirmpassword=$$v},expression:"confirmpassword"}})]}}],null,true)}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prepend-icon":"key","error-messages":errors,"label":"Email","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.getpass}},[_vm._v("Change password")])],1)],2)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }