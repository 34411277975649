export const data = [
  {'name': 'Home'},
  {'name': 'Office furniture'},
  {'name': 'Home furniture'},
  {'name': 'Stationery'},
  {'name': 'Computers'},
  {'name': 'Office chairs'},
  {'name': 'Office desks'},
  {'name': 'Reception desks'},
  {'name': 'Storage'},
  {'name': 'Office accessories'},
  // {'name': 'Desks'},
  {'name': 'Coffee Tables'},
  {'name': 'Dinings'},
  {'name': 'Sofas'},
  {'name': 'Couches'},
  {'name': 'TV stands'},
  {'name': 'Beds'},
  {'name': 'Wardrobes'},
  {'name': 'Dressing tables'},
  {'name': 'Office Stationery'},
  {'name': 'School Stationery'},
  {'name': 'Exercise Books'},
  {'name': 'Files and folders'},
  {'name': 'Markers'},
  {'name': 'Note Books Diaries and planners'},
  {'name': 'Pen and Pencils'},
  {'name': 'Accounting Books'},
  {'name': 'Technical Items'},
  {'name': 'Writing Pads'},
  {'name': 'Cards and Gifting'},
  {'name': 'Arts and Craft'},
  {'name': 'Board and Presentation'},
  {'name': 'High school textbooks'},
  {'name': 'Primary school Textbooks'},
  // {'name':'Laptop'},
  // {'name':'Desktop'},
  // {'name':'Monitor'},
  // {'name':'CPU'},
  {'name':'Storage'},
  // {'name':'Mouse'},
  // {'name':'Toners and Cartridge'},
  // {'name':'Softwares and Antivirus'},
  {'name': 'Aboutus'},
  // {'name': 'Contactus'},
  {'name': 'Search'},
  {'name': 'Blog'},
  {'name': 'faq'},
  {'name': 'help'},
  {'name': 'support'},
  {'name': 'terms'},
  {'name': 'contract'},
  {'name': 'privacy'}
]
export const supercategory = [
  {'name': 'Office furniture',
  'category': [
    {'name': 'Office chairs'},
    {'name': 'Office desks'},
    {'name': 'Reception desks'},
    {'name': 'Storage'},
    {'name': 'Office accessories'}
  ] 
},
  {'name': 'Home furniture',
  'category': [
    {'name': 'Coffee Tables'},
    {'name': 'Dinings'},
    {'name': 'Sofas'},
    {'name': 'Couches'},
    {'name': 'TV stands'},
    {'name': 'Beds'},
    {'name': 'Wardrobes'},
    {'name': 'Dressing tables'}
    ]
  },
  {'name': 'Stationery',
    'category': [
      {'name': 'Office Stationery'},
      {'name': 'School Stationery'},
      {'name': 'Exercise Books'},
      {'name': 'Files and folders'},
      {'name': 'Markers'},
      {'name': 'Note Books Diaries and planners'},
      {'name': 'Pen and Pencils'},
      {'name': 'Accounting Books'},
      {'name': 'Technical Items'},
      {'name': 'Writing Pads'},
      {'name': 'Cards and Gifting'},
      {'name': 'Arts and Craft'},
      {'name': 'Board and Presentation'},
      {'name': 'High school textbooks'},
      {'name': 'Primary school Textbooks'}
    ]    
},
  {'name': 'Computers',
    'category': [
      {'name':'Laptops'},
      {'name':'Desktops'},
      {'name':'Monitors'},
      {'name':'CPUs'},
      {'name':'Storages'},
      {'name':'Mouses'},
      {'name':'Toners and Cartridges'},
      {'name':'Softwares and Antiviruses'},

    ]  
}
]

export const majorcategory = [
  {'name': 'Desks'},
  {'name': 'Chairs'},
  {'name': 'Books'},
  {'name': 'Accessories'},
  {'name': 'Stationery'},
  {'name': 'Storage'},
]
export const category = [
]