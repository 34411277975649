<template>  
<div>
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
          <v-card class="elevation-12">
               <v-row class="fill-height">
                 
                 <v-col cols="12" md="12">
                    <v-card-text class="mt-12">
                      <h1 class="text-center display-2 teal--text text--accent-3">
                        {{title}}
                      </h1>
                      
                      <template >  
                                  <v-snackbar
                                  :timeout="timeout"
                                  v-model="snackbar"
                                  :value="true"
                                  right
                                  shaped
                                  bottom
                                  dismisable
                                  color="red"
                                >
                              <h2>{{errs.error}}</h2>
                              <p>{{errs.message}}</p>
                                </v-snackbar>
                            
                          </template>
                        <v-form>
                                 <v-container>
                                  <v-row>
                                    <v-col>
                                      <p>Name {{form.name}}</p>
                                    </v-col>
                                  </v-row>
                                   <v-row>
                                     
                                  <v-col
                                      cols="12"
                                      md="8"
                                      v-if="data"
                                    >
                                   <v-autocomplete
                                        v-model="form.subject"
                                        :items="data"
                                        chips
                                        clearable
                                        hide-details
                                        hide-selected
                                        item-text="name"
                                        item-value="title"
                                        label="Search subject..."
                                        solo
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title>
                                              Search for 
                                              <strong>Subject</strong>
                                            </v-list-item-title>
                                          </v-list-item>
                                        </template>
                                        <template v-slot:selection="{ attr, on, item, selected }">
                                          <v-chip
                                            v-bind="attr"
                                            :input-value="selected"
                                            color="blue-grey"
                                            class="white--text"
                                            v-on="on"
                                          >
                                            <v-icon left>
                                              mdi-check-box-outline
                                            </v-icon>
                                            <span v-text="item.name"></span>
                                          </v-chip>
                                        </template>
                                        <template v-slot:item="{ item }">
                                          <v-list-item-avatar
                                            color="indigo"
                                            class="headline font-weight-light white--text"
                                          >
                                            {{ item.name.charAt(0) }}
                                          </v-list-item-avatar>
                                          <v-list-item-content>
                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                            <v-list-item-subtitle >{{item.title}}</v-list-item-subtitle>
                                          </v-list-item-content>
                                          <v-list-item-action>
                                            <v-icon>mdi-check-box-outline</v-icon>
                                          </v-list-item-action>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                  <v-col
                                      cols="12"
                                      md="4"
                                      v-if="stars"
                                    >
                                   <v-autocomplete
                                        v-model="form.rating"
                                        :items="stars"
                                        chips
                                        clearable
                                        hide-details
                                        hide-selected
                                        item-text="name"
                                        item-value="title"
                                        label="Rating ..."
                                        solo
                                      >
                                        <template v-slot:no-data>
                                          <v-list-item>
                                            <v-list-item-title>
                                              Rating
                                              <strong>Stars</strong>
                                            </v-list-item-title>
                                          </v-list-item>
                                        </template>
                                        <template v-slot:selection="{ attr, on, item, selected }">
                                          <v-chip
                                            v-bind="attr"
                                            :input-value="selected"
                                            color="blue-grey"
                                            class="white--text"
                                            v-on="on"
                                          >
                                            <v-icon left>
                                              mdi-check-box-outline
                                            </v-icon>
                                            <span v-text="item.name"></span>
                                          </v-chip>
                                        </template>
                                        <template v-slot:item="{ item }">
                                          <v-list-item-avatar
                                            color="indigo"
                                            class="headline font-weight-light white--text"
                                          >
                                            {{ item.name.charAt(0) }}
                                          </v-list-item-avatar>
                                          <v-list-item-content>
                                            <v-list-item-title v-text="item.name"></v-list-item-title>
                                            <v-list-item-subtitle >{{item.title}}</v-list-item-subtitle>
                                          </v-list-item-content>
                                          <v-list-item-action>
                                            <v-icon>mdi-check-box-outline</v-icon>
                                          </v-list-item-action>
                                        </template>
                                      </v-autocomplete>
                                    </v-col>
                                   </v-row>
                                  
                                  
                                   <v-row>
                                    <v-col
                                      cols="12"
                                      md="12"
                                    >
                                    <h2>Review</h2>
                                    <vue-editor v-model="form.review"></vue-editor>
                                    </v-col>
                                   </v-row>   
                                </v-container>
                        
                      </v-form>
                      <!-- <h3 class="text-center mt-3">have an account with us </h3> -->
                    </v-card-text>
                    <div class="text-center mt-3">
                      <v-btn class="ma-3" dark @click="Back()">Back</v-btn>
                      <v-btn class="ma-3" color="teal accent-3" @click="save()">Save</v-btn>
                    </div>
                    </v-col>
               </v-row>
              
          </v-card>
        </v-col>
      </v-row>
    </v-container>

</div>
</template>

<script>
 import axios from '@/axios'
import cons from '@/helpers/myconstants'
import { VueEditor } from "vue2-editor";

  
export default {
  name: "ordercreate",
  components: {
      VueEditor
    },
  data(){
    return{
        form:{
            usercode: '24NNgMDME3UKDwhwUaw3QYNH5eVm',
            name:'Angelina',
            rating: '',
            subject: '',
            review: ''
        },
        // page: '',
        errs:{},
        option:[],
        title:'Edit order',
        init:'api/orders/view',
        redirect: '/orders',
        store: 'api/orders',
        method: 'post',
        username: "Angelina",
        snackbar:false,
        timeout:3000,
        stars: [
        {"id":1 , name:	"1"},
        {"id":2 , name:	"2"},
        {"id":3 , name:	"3"},
        {"id":4 , name:	"4"},
        {"id":5 , name:	"5"}
        ],
        data: [
    {"id":1 , name:	"Accounting "},
    {"id":2 , name:	"Advertising "},
    {"id":3 , name:	"Anthropology "},
    {"id":4 , name:	"Architecture "},
    {"id":5 , name:	"Astronomy "},
    {"id":6 , name:	"Aviation "},
    {"id":7 , name:	"Biology "},
    {"id":8 , name:	"Business "},
    {"id":9 , name:	"Chemistry "},
    {"id":10, name:	"Communication "},
    {"id":11, name:	"Computer Science "},
    {"id":12, name:	"Criminal Justice "},
    {"id":13, name:	"Criminal Law "},
    {"id":14, name:	"Culture and Ethnic Studies"}, 
    {"id":15, name:	"Design "},
    {"id":16, name:	"Ecology "},
    {"id":17, name:	"Economics "},
    {"id":18, name:	"Education "},
    {"id":19, name:	"Engineering "},
    {"id":20, name:	"English "},
    {"id":21, name:	"Environmental studies "},
    {"id":22, name:	"Ethics "},
    {"id":23, name:	"Family and consumer science"}, 
    {"id":24, name:	"Film studies "},
    {"id":25, name:	"Finance "},
    {"id":26, name:	"Financial Management "},
    {"id":27, name:	"Geography "},
    {"id":28, name:	"Geology "},
    {"id":29, name:	"Government "},
    {"id":30, name:	"Health Care "},
    {"id":31, name:	"History "},
    {"id":32, name:	"Homeland Security "},
    {"id":33, name:	"Human Resource Management"}, 
    {"id":34, name:	"Human Services "},
    {"id":35, name:	"Humanities "},
    {"id":36, name:	"Investments "},
    {"id":37, name:	"Journalism "},
    {"id":38, name:	"Law "},
    {"id":39, name:	"Leadership "},
    {"id":40, name:	"Linguistics "},
    {"id":41, name:	"Literature "},
    {"id":42, name:	"Logic "},
    {"id":43, name:	"Logistics "},
    {"id":44, name:	"Management "},
    {"id":45, name:	"Marketing "},
    {"id":46, name:	"Mathematics "},
    {"id":47, name:	"Media "},
    {"id":48, name:	"Medicine "},
    {"id":49, name:	"Military science "},
    {"id":50, name:	"Music "},
    {"id":51, name:	"Nursing "},
    {"id":52, name:	"Nutrition "},
    {"id":53, name:	"Other "},
    {"id":54, name:	"Pharmacology "},
    {"id":55, name:	"Philosophy "},
    {"id":56, name:	"Physics "},
    {"id":57, name:	"Poetry "},
    {"id":58, name:	"Political science "},
    {"id":59, name:	"Programming "},
    {"id":60, name:	"Project Management "},
    {"id":61, name:	"Psychology "},
    {"id":62, name:	"Public health "},
    {"id":63, name:	"Religious studies "},
    {"id":64, name:	"Risk Management "},
    {"id":65, name:	"Science "},
    {"id":66, name:	"Shakespeare studies "},
    {"id":67, name:	"Social Work "},
    {"id":68, name:	"Sociology "},
    {"id":69, name:	"Sports "},
    {"id":70, name:	"Statistics "},
    {"id":71, name:	"Supply Chain Management"}, 
    {"id":72, name:	"Technology "},
    {"id":73, name:	"Theater studies "},
    {"id":74, name:	"Theology "},
    {"id":75, name:	"Tourism "},
    {"id":76, name:	"Women and gender studies"}, 
    {"id":77, name:	"World affairs "},
    {"id":78, name:	"World literature"},
    ]
    }
  },
  created(){
      this.fetchData()
      this.host = cons.host
  },
  methods:{
    Back(){
      this.$router.push(this.redirect)
    },
    View(id){
      this.$router.push(`${this.redirect}/show/${id }`)
    } ,
   async save(){
          try{
              this.$store.commit("setLoaderTrue")
            // axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
            const {data} = await axios.put(`${this.store}/${this.$route.params.code}`, 
               {
              name: this.form.name,
              usercode: this.form.usercode,
              rating: this.form.rating,
              subject: this.form.subject,
              review: this.form.review})
                if(data){
                this.$router.push(this.redirect)
                }
                this.$store.commit("setLoader")
        }catch(err){
            this.snackbar = true
            this.errs = err
                this.$store.commit("setLoader")

        }

  },

  async fetchData(){
        try{
              this.$store.commit("setLoaderTrue")
            const {data} = await axios.get(`${this.store}/${this.$route.params.code}`)
            
            // const {user} = data
            if(data){
            this.form = data
            this.form.name = this.username
            }
                this.$store.commit("setLoader")
          }catch(err){
          this.snackbar = true
          //   console.log(err)
          this.errs = err.response.data
                this.$store.commit("setLoader")
          } 
      }, 
  }
  }
</script>

<style>

</style>