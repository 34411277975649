<template>  
<div>
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
          <v-card class="elevation-12">
                  <ValidationObserver
                    ref="observer"
                    v-slot="{}"
                  >
               <v-row class="fill-height">
                 
                 <v-col cols="12" md="12">
                    <v-card-text class="mt-12">
                      <h2 class="text-center display-1 teal--text text--accent-3">
                        {{title}}
                      </h2>
                      
                      <template >  
                                  <v-snackbar
                                  :timeout="timeout"
                                  v-model="snackbar"
                                  :value="true"
                                  right
                                  shaped
                                  bottom
                                  dismisable
                                  color="red"
                                >
                              <h2>{{errs.error}}</h2>
                              <p>{{errs.message}}</p>
                                </v-snackbar>
                            
                          </template>
                        <v-form>
                                 <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      md="6"
                                    > <validation-provider
                                        v-slot="{ errors }"
                                        name="Title"
                                        rules="required"
                                      >
                                      <v-textarea
                                        counter
                                        v-model="form.title"
                                        label="Title"
                                        :error-messages="errors"   
                                        required
                                        :rules="rules"
                                        :value="value"
                                      ></v-textarea>
                                      <!-- <v-text-field
                                        v-model="form.title"
                                        :error-messages="errors"      
                                        label=" title"
                                        required
                                      ></v-text-field> -->
                                      </validation-provider>
                                    </v-col>

                                    <v-col
                                      cols="12"
                                      md="6"
                                    ><validation-provider
                                        v-slot="{ errors }"
                                        name="Meta"
                                        rules="required|"
                                      >
                                      <v-textarea
                                        counter
                                        v-model="form.meta"
                                        label="Meta"
                                        :error-messages="errors"   
                                        required
                                        :rules="rules"
                                        :value="value"
                                      ></v-textarea>
                                      <!-- <v-text-field
                                        v-model="form.meta"
                                        label="Meta"
                                        :error-messages="errors"   
                                        required
                                      ></v-text-field> -->
                                      </validation-provider>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                         <v-col
                                      cols="12"
                                      md="6"
                                    ><validation-provider
                                        v-slot="{ errors }"
                                        name="Price"
                                        rules="required|"
                                      >
                                      <v-text-field
                                        v-model="form.price"
                                        label="Price"
                                        :error-messages="errors"   
                                        required
                                        type="Number"
                                      ></v-text-field>
                                      </validation-provider>
                                    </v-col>
                                  </v-row>
                                    
                                   <v-row>
                                    <v-col
                                      cols="12"
                                      md="12"
                                    >
                                    <vue-editor v-model="form.description"></vue-editor>
                                    </v-col>
                                   </v-row>
                                   <v-row>
                                    <v-col
                                      cols="12"
                                      md="12"
                                    >
                                    <vue-editor v-model="form.summary"></vue-editor>
                                    </v-col>
                                   </v-row>
                                   <v-row>
                                    <v-col
                                      cols="12"
                                      md="6"
                                    >   <v-file-input
                                        accept="image/*"
                                        label="Change Image"
                                        required
                                        type="file"
                                        @change="onFile"
                                      ></v-file-input>
                                      </v-col>
                                      <v-col
                                      cols="12"
                                      md="6"
                                    >   
                                      </v-col>
                                  </v-row>
                                  
                                </v-container>
                        
                      </v-form>
                      <!-- <h3 class="text-center mt-3">have an account with us </h3> -->
                    </v-card-text>
                    <div class="text-center mt-3">
                      <v-btn class="ma-3" dark @click="Back()">Back</v-btn>
                      <v-btn class="ma-3" color="teal accent-3" @click="save()">Save</v-btn>
                    </div>
                    </v-col>
               </v-row>
                  </ValidationObserver>
              
          </v-card>
        </v-col>
      </v-row>
    </v-container>

</div>
</template>

<script>
 import axios from '@/axios'
import cons from '@/helpers/myconstants'
import { VueEditor } from "vue2-editor";

  import { required, email, max } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
 setInteractionMode('eager')

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })

export default {
  name: "blogcreate",
  components: {
      ValidationProvider,
      ValidationObserver,
      VueEditor
    },
  data(){
    return{
        form:{
            title: '',
            meta: '',
            description: '',
            summary: '',
            price: 0,
            picture:{}
        },
        majorcats:[],
        errs:{},
        option:[],
        title:'Create A Gift',
        init:'api/gifts/view',
        redirect: '/gifts',
        store: 'api/gifts',
        method: 'post',
        snackbar:false,
        timeout:3000
    }
  },
  created(){
      this.host = cons.host
  },
  methods:{
    onFile(e){
      console.log(e)
      this.form.picture = e
    },
    Back(){
      this.$router.push(this.redirect)
    },
    View(id){
      this.$router.push(`${this.redirect}/show/${id }`)
    } ,
   async save(){
          try{
              this.$store.commit("setLoaderTrue")
                 let fd = new FormData();
                 fd.append("picture", this.form.picture, this.form.picture.name)
                 fd.append("title", this.form.title)
                 fd.append("url", this.form.title.split(' ').join('-'))
                  fd.append("altertag", this.form.title.split(' ').join('-'))
                 fd.append("meta", this.form.meta)
                 fd.append("description", this.form.description)
                 fd.append("price", this.form.price)
                 fd.append("summary", this.form.summary)
            // axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
            const {data} = await axios.post(`${this.store}`, 
               fd ,{'Content-Type': 'multipart/form-data'})

                if(data){
                this.$router.push(this.redirect)
                }
                this.$store.commit("setLoader")
        }catch(err){
            this.snackbar = true
            this.errs = err
                this.$store.commit("setLoader")

        }

  },
  }
  }
</script>

<style>

</style>