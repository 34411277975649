<template>
  <div>
    <v-form>
      <v-container class="fill-height">
          <h1>Order</h1><v-spacer></v-spacer>
          <h1 v-if="form.delivered ==='delivered'" class="design">{{form.delivered}}</h1>
          <h1 v-if="form.delivered ==='canceled'" class="cancel">{{form.delivered}}</h1>
          <h1 v-if="form.delivered ==='pending'" class="pending">{{form.delivered}}</h1>

         <!-- <v-btn class="ma-2" 
      depressed
      color="success"
      @click="GetPdf()"
    >
      PDF
    </v-btn> -->
        <v-row align="center" justify="center" v-if="form">
          <v-col cols="12" sm="8" md="4">
            <v-card class="mx-auto">
              <v-card-title>Name: <span class="design">{{form.name}}</span> </v-card-title>
              <v-card-subtitle>Email: <span class="design">{{form.email}}</span> </v-card-subtitle>
              <v-card-title>Code: <span class="design">{{form.code}}</span></v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-card class="mx-auto">
              <v-card-title>Phone: <span class="design">{{form.phone}}</span></v-card-title>
              <v-card-title>House: <span class="design">{{form.house}}</span></v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-card class="mx-auto" min-height="130">
              <v-card-title> Location:<span class="design">{{form.location}}</span></v-card-title>
              <v-card-title> Street:<span class="design">{{form.street}}</span></v-card-title>
            </v-card>
          </v-col>
        </v-row>

        <v-row  v-if="cart.length > 0">
          <v-col cols="12" md="12">
            <v-card>
                 <div><h1>Products</h1></div>
              <v-data-table :headers="cartheader" :items="cart" item-key="ID" class="elevation-1 display-2" style="font-size:10rem" :footer-props="{
                  'items-per-page-options': [5,10, 20, 30, 40, 50]
                }" :items-per-page="10">  
          <template v-slot:[`item.price`]="{ item }">
              
                ksh{{ formatcurrency(item.price) }}
            </template>  
          <template v-slot:[`item.total`]="{ item }">
              
                ksh{{ formatcurrency(item.quantity * item.price) }}
            </template>
            
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                color="green"
                class="mr-2"
                @click="View(item.code)"
              >
                mdi-eye
              </v-icon>
          
            </template>
            </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row  v-if="tdf">
          <v-col cols="12" md="8">
            <v-card>
                 <div><h1>Addons</h1></div>
              <v-data-table :headers="addonsheader" :items="stuffs" item-key="ID" class="elevation-1 display-2" style="font-size:10rem" :footer-props="{
                  'items-per-page-options': [5,10, 20, 30, 40, 50]
                }" :items-per-page="10">
          <template v-slot:[`item.image`]="{ item }">
                  <v-img
                    :src="`${host}${item.image}`"
                    contain
                    max-height="50"
                    max-width="50"
                    class="grey darken-4"
                  ></v-img>
            </template>
                </v-data-table>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" v-if="picturer">
                  <v-img
                    :src="`${host}${picturer[0].name}`"
                    contain
                    max-height="200"
                    class="grey darken-4"
                  ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <p>Instruction: <br />{{form.instructions}}</p>
          </v-col>

          <v-col cols="12" md="4">
            <v-card class="mx-auto">
              <v-card-title>Amount: ksh{{formatcurrency(form.amount)}}</v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

  </div>
</template>

<script>
import cons from '@/helpers/myconstants'
import moment from 'moment'
import formatMoney from '@/helpers/currencyformat'
import axios from '@/axios'

export default {
  name: "Customershow",
  data() {
    return {
      form: {},
      errs: {},
      option: {},
      title: 'Create',
      init: 'api/sales',
      redirect: '/sales',
      store: 'api/sales',
      method: 'post',
      snackbar: false,
      tdf: false,
      timeout: 3000,
      customer: {},
      invoice: {},
      imagens: [],
      cart: [],
      stuffs:[],
      picturer: '',
      host: '',
      cartheader: [{
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Specs',
          value: 'specs'
        },
        {
          text: 'Decor',
          value: 'decor'
        },
        {
          text: 'Message',
          value: 'message'
        },
        {
          text: 'Quantity',
          value: 'quantity'
        },
        {
          text: 'Price',
          value: 'price'
        },
        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ],
      addonsheader: [{
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Quantity',
          value: 'quantity'
        },
        {
          text: 'Price',
          value: 'price'
        },
        {
          text: 'Image',
          value: 'image'
        }
      ]

    }
  },
  created() {
    this.fetchData()
      this.host = cons.host
  },
  methods: {
    formatcurrency(d) {
          return formatMoney(d)
        },
      formatdate(d) {
          return moment(d).format('L');
        },
    View(code) {
      this.tdf = true
      const res = this.cart.filter(item => item.code === code)
      this.picturer = this.imagens.filter(i => i.cartorder === res[0].naming)
      console.log('>>>>>>>>>>', res)
      this.stuffs = res[0].stuffs
    },
 async fetchData(){
      try{
              this.$store.commit("setLoaderTrue")
          const {data} = await axios.get(`${this.store}/${this.$route.params.code}`)
            if (data === ""){
            throw 'token problem' ;
          }

          this.form = data
          this.cart = data.cart
          this.imagens = data.images
                this.$store.commit("setLoader")
        }catch(err){
          if (err === "token problem"){
            console.log("ssssssss>>>>>>>>>>>>>>", err)
              this.$store.commit("setsnacker", {mess:"you have an expired token", color: "red"})  
              this.$store.commit("setLoader")
              this.$router.push("/login")
              return
          }
          if (err.response.data.message === "That token is invalid!"){
              this.$store.commit("setsnacker", {mess:err.response.data.message, color: "red"})
              this.$router.push("/login")
          }
          this.errs = err.response.data
          this.$store.commit("setsnacker", {mess:err.response.data, color: "red"})
          this.$store.commit("setLoader")
        }
    }
  //  async GetPdf(){
  //     try{
  //        const res =  await axios.get(`api/invoice/download/${this.invoice.code}`,{ responseType: 'blob' })
  //       const blob = new Blob([res.data], { type: 'application/pdf' })
  //       const link = document.createElement('a')
  //       link.href = URL.createObjectURL(blob)
  //       link.download = this.invoice.code
  //       link.click()
  //       URL.revokeObjectURL(link.href)
  //     } catch (err) {
  //       this.snackbar = true
  //       //   console.log(err)
  //       this.errs = err.response.data 
  //               this.$store.commit("setLoader")
  //     }
  //   }
  },
}
</script>

<style>

.design{
  color: green;
}
.cancel{
  color: red
}
.pending{
  color:grey
}
</style>
