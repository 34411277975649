var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"fill-height"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"10"}},[_c('v-card',{staticClass:"elevation-12"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-row',{staticClass:"fill-height"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card-text',{staticClass:"mt-12"},[_c('h1',{staticClass:"text-center display-2 teal--text text--accent-3"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),[_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"value":true,"right":"","shaped":"","bottom":"","dismisable":"","color":"red"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('h2',[_vm._v(_vm._s(_vm.errs.error))]),_c('p',[_vm._v(_vm._s(_vm.errs.message))])])],_c('v-form',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":" title","required":""},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Meta","rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Meta","error-messages":errors,"required":""},model:{value:(_vm.form.meta),callback:function ($$v) {_vm.$set(_vm.form, "meta", $$v)},expression:"form.meta"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"url","rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Url","error-messages":errors,"required":""},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"videourl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Video Url","error-messages":errors,"required":""},model:{value:(_vm.form.videourl),callback:function ($$v) {_vm.$set(_vm.form, "videourl", $$v)},expression:"form.videourl"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"header1","rules":"required|"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Header 1","error-messages":errors,"required":""},model:{value:(_vm.form.header1),callback:function ($$v) {_vm.$set(_vm.form, "header1", $$v)},expression:"form.header1"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"header2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Header 2","error-messages":errors,"required":""},model:{value:(_vm.form.header2),callback:function ($$v) {_vm.$set(_vm.form, "header2", $$v)},expression:"form.header2"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{on:{"click":_vm.createproducts}},[_vm._v("Add Product")])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h2',[_vm._v("Product Listings")]),_vm._l((_vm.listings),function(listing,i){return _c('ol',{key:i},[_c('li',[_vm._v(_vm._s(listing.name))])])})],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('vue-editor',{model:{value:(_vm.form.summary),callback:function ($$v) {_vm.$set(_vm.form, "summary", $$v)},expression:"form.summary"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-file-input',{attrs:{"accept":"image/*","label":"Change Image","required":"","type":"file"},on:{"change":_vm.onFile}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}})],1)],1)],1)],2),_c('div',{staticClass:"text-center mt-3"},[_c('v-btn',{staticClass:"ma-3",attrs:{"dark":""},on:{"click":function($event){return _vm.Back()}}},[_vm._v("Back")]),_c('v-btn',{staticClass:"ma-3",attrs:{"color":"teal accent-3"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save")])],1)],1)],1)]}}])})],1)],1)],1),_c('products',{attrs:{"dialogproducts":_vm.dialogproducts,"productsclose":_vm.productsclose,"getproductlist":_vm.getproductlist}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }