<template>
  <mainlayout>
<router-view>
  
</router-view>
  </mainlayout>
</template>

<script>
export default {

}
</script>

<style>

</style>