<template>
  <v-dialog
          v-model="dialogurl"
          max-width="800px"
          persistent
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{title}}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="10"
                    md="10"
                  >
                    <v-text-field
                      v-model="product.url"
                      label="url"
                    ></v-text-field>
                  </v-col>
                  </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save" 
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
</template>

<script>
 import axios from '@/axios'
export default {
    props: ['dialogurl','closeurl','product'],
    data(){
      return{
        url: '',
        store:'api/urlupdator',
        title: 'url'
      }
    },
    methods:{
      close(){
          this.closeurl()
      },
      async save(){
           try{
              this.$store.commit("setLoaderTrue")
            let fd = new FormData();
            this.url = this.product.url
            fd.append("url", this.url.split(' ').join('-'))
            await axios.put(`${this.store}/${this.product.code}`, fd ,{'Content-Type': 'multipart/form-data'})
            this.close()
            // this.finishedloading()
            this.$store.commit("setLoader")
          }catch(err){
            this.close()
            console.log(err)
                this.$store.commit("setLoader")
          }
        }
    }

}
</script>

<style>

</style>